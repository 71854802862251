import React, { useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { Link } from "gatsby";
import { SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "../styles/bocado-home-experiences-card.module.scss";
import { experienceLinkGenerator } from "../utils/link-generator";

moment.locale("es");

const BocadoHomeExperiencesCard = ({
    image,
    cardTitle,
    // buttonText,
    date,
    pageTitle,
    // pageFormTitle,
    // pageFormText,
    // setShowExperienceModal,
    // setExperienceFormTitle,
    // setExperienceFormText,
    mainComponent,
}) => {
    // const displayModal = () => {
    //   setShowExperienceModal(true)
    //   setExperienceFormTitle(pageFormTitle)
    //   setExperienceFormText(pageFormText)
    // }

    return mainComponent ? (
        <div className={styles.bocadoExperiencesCardContainerWithRightMargin}>
            <Link
                to={experienceLinkGenerator(pageTitle)}
                onClick={() => {
                    window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Content",
                        event_action: `${cardTitle}"`,
                        event_label: "Ver Más",
                        interaction: true,
                        component_name: "boton_ver_mas",
                        element_text: "Ver Más",
                    });
                }}
            >
                <GatsbyImage
                    image={image}
                    alt={cardTitle}
                    className={styles.bocadoExperiencesCardImageInMainComponent}
                />
            </Link>
            <div className={styles.bocadoExperiencesCardTitle}>{cardTitle}</div>
            <div className={styles.bocadoExperiencesCardDate}>
                {date
                    ? "Próxima fecha: " +
                      moment(date).format("D [de] MMMM [de] YYYY")
                    : " "}
            </div>
            <div className={styles.bocadoExperiencesCardButtonsContainer}>
                <Link
                    to={experienceLinkGenerator(pageTitle)}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Content",
                            event_action: `${cardTitle}"`,
                            event_label: "Ver Más",
                            interaction: true,
                            component_name: "boton_ver_mas",
                            element_text: "Ver Más",
                        });
                    }}
                >
                    <button
                        className={styles.bocadoExperiencesCardSeeMoreButton}
                    >
                        Ver más
                    </button>
                </Link>
                {/* <button
          className={styles.bocadoExperiencesCardMainButton}
          onClick={displayModal}
        >
          {buttonText}
        </button> */}
            </div>
        </div>
    ) : (
        <SwiperSlide>
            <div className={styles.bocadoExperiencesCardContainer}>
                <Link
                    to={experienceLinkGenerator(pageTitle)}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Content",
                            event_action: `${cardTitle}"`,
                            event_label: "Ver Más",
                            interaction: true,
                            component_name: "boton_ver_mas",
                            element_text: "Ver Más",
                        });
                    }}
                >
                    <GatsbyImage
                        image={image}
                        alt={cardTitle}
                        className={styles.bocadoExperiencesCardImage}
                    />
                </Link>
                <div className={styles.bocadoExperiencesCardTitle}>
                    {cardTitle}
                </div>
                <div className={styles.bocadoExperiencesCardDate}>
                    {date
                        ? "Próxima fecha: " +
                          moment(date).format("D [de] MMMM [de] YYYY")
                        : " "}
                </div>
                <div className={styles.bocadoExperiencesCardButtonsContainer}>
                    <Link
                        to={experienceLinkGenerator(pageTitle)}
                        onClick={() => {
                            window.dataLayer.push({
                                event: "GAEvent",
                                event_category: "Content",
                                event_action: `${cardTitle}"`,
                                event_label: "Ver Más",
                                interaction: true,
                                component_name: "boton_ver_mas",
                                element_text: "Ver Más",
                            });
                        }}
                    >
                        <button
                            className={
                                styles.bocadoExperiencesCardSeeMoreButton
                            }
                        >
                            Ver más
                        </button>
                    </Link>
                    {/* <button
            className={styles.bocadoExperiencesCardMainButton}
            onClick={displayModal}
          >
            {buttonText}
          </button> */}
                </div>
            </div>
        </SwiperSlide>
    );
};

export default BocadoHomeExperiencesCard;
