import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useRecoilValue } from "recoil";
import styles from "../styles/menu.module.scss";
import logo from "../images/stella_logo3.png";
import instagramLogo from "../svg/bocado-footer-instagram.svg";
import youTubeLogo from "../svg/bocado-footer-youtube.svg";
import facebookLogo from "../svg/bocado-footer-facebook.svg";
import twitterLogo from "../svg/bocado-footer-twitter.svg";
import hamburger from "../svg/bocado-hamburger.svg";
import x from "../svg/bocado-mobile-menu-x.svg";
import star from "../svg/bocado-star.svg";

import { cartTotalItemsSelector } from "../recoil/atoms.js";

// @ts-ignore
import { window } from "browser-monads";

const CartSummary = ({ numberOfItems, setShowCartDrawer }) => (
    <div
        className={styles.menuCartContainer}
        onClick={() => {
            window.dataLayer.push({
                event: "GAEvent",
                event_category: "Header",
                event_action: `Click`,
                event_label: ``,
                interaction: true,
                component_name: "boton_carrito",
                element_text: "Carrito",
            });
            setShowCartDrawer(true);
        }}
    >
        <div className={styles.menuCartLabel}>Carrito</div>
        <div className={styles.menuCartNumber}>{numberOfItems}</div>
    </div>
);

const SocialMediaIcons = ({ isMobile, onlySocialMediaIcons }) => (
    <div
        className={
            isMobile && onlySocialMediaIcons
                ? styles.menuMobileNavbarSocialMedia
                : styles.menuSocialMedia
        }
    >
        <a
            target="_blank"
            href="https://www.instagram.com/stellaartois.ar/"
            rel="noreferrer"
        >
            <img
                className={
                    isMobile && onlySocialMediaIcons
                        ? styles.menuMobileNavbarSocialMediaLogo
                        : styles.socialMediaLogo
                }
                src={instagramLogo}
                alt="Instagram"
            />
        </a>
        <a
            href="https://www.youtube.com/user/StellaArtoisAR"
            target="_blank"
            rel="noreferrer"
        >
            <img
                className={
                    isMobile && onlySocialMediaIcons
                        ? styles.menuMobileNavbarSocialMediaLogo
                        : styles.socialMediaLogo
                }
                src={youTubeLogo}
                alt="YouTube"
            />
        </a>
        <a
            target="_blank"
            href="https://www.facebook.com/StellaArtoisArgentina"
            rel="noreferrer"
        >
            <img
                className={
                    isMobile && onlySocialMediaIcons
                        ? styles.menuMobileNavbarSocialMediaLogo
                        : styles.socialMediaLogo
                }
                src={facebookLogo}
                alt="Facebook"
            />
        </a>
        <a
            target="_blank"
            href="https://twitter.com/stellaartois_ar"
            rel="noreferrer"
        >
            <img
                src={twitterLogo}
                alt="Twitter"
                className={
                    isMobile && onlySocialMediaIcons
                        ? styles.menuMobileNavbarSocialMediaLogo
                        : styles.socialMediaLogo
                }
            />
        </a>
    </div>
);

const Menu = ({
    isMobile,
    setShowNewsletterModal,
    onlySocialMediaIcons,
    setShowCartDrawer,
}) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [delayedHideMobileMenu, setDelayedHideMobileMenu] = useState(true);

    const cartTotalItems = useRecoilValue(cartTotalItemsSelector);

    useEffect(() => {
        showMobileMenu
            ? setDelayedHideMobileMenu(false)
            : setTimeout(function () {
                  setDelayedHideMobileMenu(true);
              }, 550);
    }, [showMobileMenu]);

    return (
        <>
            <div className={styles.menuMobileNavbar}>
                {!onlySocialMediaIcons && (
                    <img
                        className={styles.menuMobileNavbarHamburger}
                        src={hamburger}
                        alt="Hamburger Button"
                        onClick={() => setShowMobileMenu(true)}
                    />
                )}
                <div
                    className={
                        onlySocialMediaIcons
                            ? styles.menuMobileNavbarLogoContainerWithoutHamburger
                            : styles.menuMobileNavbarLogoContainer
                    }
                >
                    <img
                        className={styles.menuMobileNavbarLogo}
                        src={logo}
                        alt="Stella Artois"
                    />
                </div>
                {onlySocialMediaIcons ? (
                    <SocialMediaIcons
                        isMobile={isMobile}
                        onlySocialMediaIcons={onlySocialMediaIcons}
                    />
                ) : null
                // (
                //     <CartSummary
                //         numberOfItems={cartTotalItems}
                //         setShowCartDrawer={setShowCartDrawer}
                //     />
                // )
                }
            </div>
            <div
                className={`${
                    showMobileMenu
                        ? styles.menuMobileNavbarDarkBackground
                        : styles.menuMobileNavbarDarkBackgroundFadeOut
                } ${
                    delayedHideMobileMenu &&
                    styles.menuMobileNavbarDarkBackgroundHidden
                }`}
            ></div>
            {/* <div
        className={`${styles.menuMobileNavbarBackgroundContainer} ${
          showMobileMenu && styles.menuMobileNavbarBackgroundContainerVisible
        }`}
      > */}
            {/* <StaticImage
          src="../images/Menu/background.jpg"
          height={1080}
          quality={70}
          formats={["AUTO"]}
          alt="Stella Artois"
          className={styles.menuMobileNavbarBackground}
        /> */}
            {/* </div> */}
            <div
                className={`${styles.menuContainer} ${
                    showMobileMenu && styles.menuVisible
                }
        `}
            >
                {!isMobile && (
                    <Link
                        to="/"
                        title="Inicio"
                        // className={styles.menuLogoLink}
                        onClick={() => {
                            window.dataLayer.push({
                                event: "GAEvent",
                                event_category: "Header",
                                event_action: `Click`,
                                event_label: `https://www.stellaartois.com.ar`,
                                interaction: true,
                                component_name: "boton_logo",
                                element_text: "Logo Stella",
                            });
                            setShowMobileMenu(false);
                        }}
                    >
                        <img
                            className={styles.menuLogo}
                            src={logo}
                            alt="Stella Artois"
                        />
                    </Link>
                )}
                {!onlySocialMediaIcons ? (
                    <>
                        <img
                            className={styles.menuStar}
                            src={star}
                            alt="Stella Artois Star"
                        />
                        <img
                            className={styles.menuMobileNavbarClose}
                            src={x}
                            alt="Close"
                            onClick={() => setShowMobileMenu(false)}
                        />
                        <div className={styles.menuItems}>
                            <Link
                                to="/"
                                title="Inicio"
                                className={`${
                                    window?.location.pathname === "/" &&
                                    styles.menuSingleItemCurrentAnchor
                                }`}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Header",
                                        event_action: `Click`,
                                        event_label: `https://www.stellaartois.com.ar`,
                                        interaction: true,
                                        component_name: "boton_inicio",
                                        element_text: "Inicio",
                                    });
                                    setShowMobileMenu(false);
                                }}
                            >
                                Inicio
                            </Link>
                            <Link
                                to="/contenidos"
                                title="Contenidos"
                                className={`${
                                    window?.location.pathname ===
                                        "/contenidos" &&
                                    styles.menuSingleItemCurrentAnchor
                                }`}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Header",
                                        event_action: `Click`,
                                        event_label: `https://www.stellaartois.com.ar/contenidos`,
                                        interaction: true,
                                        component_name: "boton_contenidos",
                                        element_text: "Contenidos",
                                    });
                                    setShowMobileMenu(false);
                                }}
                            >
                                Contenidos
                            </Link>
                            <Link
                                to="/experiencias"
                                title="Experiencias"
                                className={`${
                                    window?.location.pathname ===
                                        "/experiencias" &&
                                    styles.menuSingleItemCurrentAnchor
                                }`}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Header",
                                        event_action: `Click`,
                                        event_label: `https://www.stellaartois.com.ar/experiencias`,
                                        interaction: true,
                                        component_name: "boton_experiencias",
                                        element_text: "Experiencias",
                                    });
                                    setShowMobileMenu(false);
                                }}
                            >
                                Experiencias
                            </Link>
                            <a
                                href="https://www.tada.com.ar/catalogo/grupo/stella-artois"
                                title="Comprar"
                                target="_blank"
                                className={`${
                                    window?.location.pathname ===
                                        "/nuestros-productos" &&
                                    styles.menuSingleItemCurrentAnchor
                                }`}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Header",
                                        event_action: `Click`,
                                        event_label: `https://www.tada.com.ar/catalogo/grupo/stella-artois`,
                                        interaction: true,
                                        component_name: "boton_comprar",
                                        element_text: "Comprar",
                                    });
                                    setShowMobileMenu(false);
                                }}
                            >
                                Comprar
                            </a>
                            <Link
                                to="/sobre-stella"
                                title="Sobre Stella"
                                className={`${
                                    window?.location.pathname ===
                                        "/sobre-stella" &&
                                    styles.menuSingleItemCurrentAnchor
                                }`}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Header",
                                        event_action: `Click`,
                                        event_label: `https://www.stellaartois.com.ar/sobre-stella`,
                                        interaction: true,
                                        component_name: "boton_sobre_stella",
                                        element_text: "Sobre Stella",
                                    });
                                    setShowMobileMenu(false);
                                }}
                            >
                                Sobre Stella
                            </Link>
                        </div>
                        <button
                            className={styles.menuButton}
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Content",
                                    event_action: `Boton`,
                                    event_label: "Boton",
                                    interaction: true,
                                    component_name: "boton_sumate",
                                    element_text: "Sumate",
                                });
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Header",
                                    event_action: `Click`,
                                    event_label: ``,
                                    interaction: true,
                                    component_name: "boton_sumate",
                                    element_text: "Sumate",
                                });
                                setShowNewsletterModal(true);
                            }}
                        >
                            Sumate
                        </button>
                    </>
                ) : null}
                {/* {!isMobile && !onlySocialMediaIcons && (
                    <CartSummary
                        numberOfItems={cartTotalItems}
                        setShowCartDrawer={setShowCartDrawer}
                    />
                )} */}
                {isMobile || onlySocialMediaIcons ? (
                    <SocialMediaIcons
                        isMobile={isMobile}
                        onlySocialMediaIcons={onlySocialMediaIcons}
                    />
                ) : null}
            </div>
        </>
    );
};

export default Menu;
