import React, { useState, useEffect, useRef } from "react"
import { getImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import BocadoHomeExperiencesCard from "./bocado-home-experiences-card"
import leftArrow from "../svg/bocado-arrow-left.svg"
import rightArrow from "../svg/bocado-arrow-right.svg"
import styles from "../styles/bocado-home-experiences.module.scss"
import "swiper/scss"

const BocadoHomeExperiences = ({
  cardsArray,
  homePage,
  isMobile,
  setShowExperienceModal,
  setExperienceFormTitle,
  setExperienceFormText,
  mainComponent,
}) => {
  const swiperRef = useRef(null)


  const goNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const params = {
    freeMode: true,
    slidesOffsetAfter: 10,
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 40,
      },
      480: {
        slidesPerView: 3.1,
      },
    },
  }

  return (
    <div
      className={
        mainComponent
          ? styles.bocadoExperiencesContainerWideTopMargin
          : styles.bocadoExperiencesContainer
      }
      id={mainComponent ? "inicio" : null}
    >
      {homePage ? (
        <div
          className={
            mainComponent
              ? styles.bocadoExperiencesTitleInMainComponent
              : styles.bocadoExperiencesTitle
          }
        >
          Experiencias
        </div>
      ) : (
        <div className={styles.bocadoExperiencesSmallTitle}>
          Otras experiencias
        </div>
      )}

      {mainComponent ? (
        <div class={styles.bocadoExperiencesNoSwiperContainer}>
          {cardsArray.map(
            ({
              cardTitle,
              cardButtonText,
              date,
              experiences_card_image,
              pageTitle,
              pageFormTitle,
              pageFormText,
            }) => (
              <BocadoHomeExperiencesCard
                image={getImage(experiences_card_image)}
                cardTitle={cardTitle}
                buttonText={cardButtonText}
                date={date}
                pageTitle={pageTitle}
                isMobile={isMobile}
                pageFormTitle={pageFormTitle}
                pageFormText={pageFormText}
                setShowExperienceModal={setShowExperienceModal}
                setExperienceFormTitle={setExperienceFormTitle}
                setExperienceFormText={setExperienceFormText}
                mainComponent={mainComponent}
              />
            )
          )}
        </div>
      ) : (
        <Swiper
          containerClass={styles.bocadoExperiencesSwiperContainer}
          ref={swiperRef}
          {...params}
        >
          {cardsArray.map(
            ({
              cardTitle,
              cardButtonText,
              date,
              experiences_card_image,
              pageTitle,
              pageFormTitle,
              pageFormText,
            }) => (
              <BocadoHomeExperiencesCard
                image={getImage(experiences_card_image)}
                cardTitle={cardTitle}
                buttonText={cardButtonText}
                date={date}
                pageTitle={pageTitle}
                isMobile={isMobile}
                pageFormTitle={pageFormTitle}
                pageFormText={pageFormText}
                setShowExperienceModal={setShowExperienceModal}
                setExperienceFormTitle={setExperienceFormTitle}
                setExperienceFormText={setExperienceFormText}
                mainComponent={mainComponent}
              />
            )
          )}
        </Swiper>
      )}
      {!mainComponent && (
        <div className={styles.bocadoExperiencesArrowButtonsRow}>
          <div className={styles.bocadoExperiencesArrowButtonsContainer}>
            <img
              src={leftArrow}
              alt="Previous Experience"
              className={styles.bocadoExperiencesArrowButton}
              onClick={goPrev}
            />
            <img
              src={rightArrow}
              alt="Next Experience"
              className={styles.bocadoExperiencesArrowButton}
              onClick={goNext}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BocadoHomeExperiences
