import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { graphql } from "gatsby";
import { navigate } from "gatsby";
import { IsMobileDevice } from "../utils/functions";
import Menu from "../components/menu.jsx";
import Footer from "../components/footer.jsx";
import BocadoHomeExperiences from "../components/bocado-home-experiences";
import BocadoModal from "../components/bocado-modal";
import BocadoCart from "../components/bocado-cart";
import Seo from "../components/seo";
import styles from "../styles/home.module.scss";
import {
    isMobileAtom,
    showNewsletterModalAtom,
    showCartDrawerAtom,
} from "../recoil/atoms.js";
import Cookies from "js-cookie";

import { window, document } from "browser-monads";

const ExperiencesPage = ({ data }) => {
    const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);
    const [showNewsletterModal, setShowNewsletterModal] = useRecoilState(
        showNewsletterModalAtom
    );
    const [showExperienceModal, setShowExperienceModal] = useState(false);
    const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false);
    const [showCartDrawer, setShowCartDrawer] = useRecoilState(
        showCartDrawerAtom
    );

    const [experienceFormTitle, setExperienceFormTitle] = useState("");
    const [experienceFormText, setExperienceFormText] = useState("");

    useEffect(() => {
        setIsMobile(IsMobileDevice());
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight / 100}px`
        );
    }, []);

    useEffect(() => {
        // const td = Cookies.get("_td");
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Experiencias",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.stellaartois.com.ar/experiencias",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    useEffect(() => {
        let local = localStorage.getItem("age");
        let session = sessionStorage.getItem("age");
        if (!local && !session)
            navigate("/agegate", {
                state: {
                    newPath: "/experiencias",
                },
            });
    }, []);

    return (
        <>
            <Seo title="Stella Artois" />
            <div className={styles.homeContainer}>
                <BocadoCart
                    showCartDrawer={showCartDrawer}
                    setShowCartDrawer={setShowCartDrawer}
                />
                <Menu
                    isMobile={isMobile}
                    setShowNewsletterModal={setShowNewsletterModal}
                    setShowCartDrawer={setShowCartDrawer}
                />
                <BocadoHomeExperiences
                    cardsArray={data.allExperiencias.nodes}
                    homePage={true}
                    isMobile={isMobile}
                    // setShowExperienceModal={setShowExperienceModal}
                    // setExperienceFormTitle={setExperienceFormTitle}
                    // setExperienceFormText={setExperienceFormText}
                    mainComponent={true}
                />
                {showNewsletterModal && (
                    <BocadoModal
                        type="newsletter"
                        isMobile={isMobile}
                        setShowModal={setShowNewsletterModal}
                        title="BIENVENIDO A BOCADO CLUB"
                        subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
                    />
                )}
                {/* {showExperienceModal && (
          <BocadoModal
            type="experience"
            isMobile={isMobile}
            setShowModal={setShowExperienceModal}
            title={experienceFormTitle}
            subtitle={experienceFormText}
          />
        )} */}
                {showFooterSuccessModal && (
                    <BocadoModal
                        isMobile={isMobile}
                        setShowModal={setShowFooterSuccessModal}
                        onlySuccess={true}
                    />
                )}
                <Footer
                    link={"/experiencias#inicio"}
                    isMobile={isMobile}
                    setShowModal={setShowFooterSuccessModal}
                />
            </div>
        </>
    );
};

export default ExperiencesPage;

export const data = graphql`
    query Experiences {
        allExperiencias(
            filter: { cardActive: { eq: true } }
            sort: { fields: position, order: ASC }
        ) {
            nodes {
                id
                cardButtonText
                cardOverline
                cardText
                cardTitle
                date
                pageActive
                pageTitle
                pageFormTitle
                pageFormText
                scriptTitle
                scriptInterest
                bases
                provinces
                question
                answer
                experiences_card_image {
                    childImageSharp {
                        gatsbyImageData(
                            height: 1080
                            quality: 70
                            formats: [AUTO]
                        )
                    }
                }
            }
        }
    }
`;
